import React, { useContext, useEffect, useState } from "react";
import PdfViewer from "./PdfViewer";
import RawLog from "./RawLog";
import ErrorLog from "./ErrorLog";
import "../components/RightPanel.css";
import {
  AiFillCaretLeft,
  AiOutlineArrowRight,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from "react-icons/ai";
import { Button } from "@chakra-ui/react";

function RightPanel(props) {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (props.view === "pdf" || props.view === "proofPdf") {
      setRender(true);
    } else {
      setRender(false);
    }
  }, [props.view]);

  return (
    <>
      {render ? (
        <PdfViewer
          pdfContainer={props.pdfContainer}
          key={props.test}
          prevScroll={props.prevScroll}
          data={props.view == "pdf" ? props.pdfData : props.proofPdfData}
          pdfFull={props.pdfFull}
          toggleEditor={props.toggleEditor}
          test={props.test}
          // scrollPos={props.scrollPos}
        />
      ) : (
        <></>
      )}
      {/* {props.view == "proofPdf" ? (
        <PdfViewer
          pdfContainer={props.pdfContainer}
          key={props.test}
          data={props.proofPdfData}
          test={props.test}
          prevScroll={props.prevScroll}
          pdfFull={props.pdfFull}
          toggleEditor={props.toggleEditor}
        />
      ) : (
        <></>
      )} */}
      {props.view == "error" ? (
        <ErrorLog ace={props.editorRef} data={props.errorData} key="error" />
      ) : (
        <></>
      )}
      {props.view == "raw" ? <RawLog key="raw" data={props.rawData} /> : <></>}
    </>
  );
}

export default RightPanel;
