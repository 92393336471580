import React, { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./PdfViewer.module.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useContext, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  flexbox,
  Tooltip,
} from "@chakra-ui/react";
import { Resize1, Resize2, TwoPage, ZoomIn, ZoomOut } from "./icons";

if (pdfjs.version === "2.16.105" && window.location.hostname !== "localhost") {
  var url =
    window.location.pathname.replace("/login", "") +
    "/static/js/pdf.worker.min.js";
} else {
  url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
}
pdfjs.GlobalWorkerOptions.workerSrc = url;
const pdfHighlightId = "pdf_highlighted_text";

function highlightPattern(text, pattern) {
  return text.replace(
    pattern,
    (value) => `<mark id="${pdfHighlightId}">${value}</mark>`
  );
}

const PdfViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const [secondpage, setsecondpage] = useState(2);
  const ref = useRef(null);
  const pageref = useRef(null);
  const [scale, setScale] = useState(0.2);
  const [twoPageView, setTwoPageView] = useState(false);

  const textRenderer = useCallback(
    (textItem) => highlightPattern(textItem.str, props.pdfsearch),
    [props.pdfsearch]
  );

  let file;
  if (props.data) {
    file = props.data;
  } else {
    file = "Loading.....";
  }

  useEffect(() => {
    let isScrollingTimeout = null;
    const isScrolling = () => {
      clearTimeout(isScrollingTimeout);
      isScrollingTimeout = setTimeout(() => {
        // const totalpage = numPages;
        const scrollPos = props.pdfContainer.current.scrollTop;
        const pageht = pageref.current.clientHeight;
        if (twoPageView) {
          let currentPage = Math.floor(scrollPos / pageht) + 1;
          let twopage = currentPage * 2 - 1;
          // if (currentPage <= totalpage / 2) {
          //   setsecondpage(twopage + 1);
          // } else {
          //   setsecondpage("-");
          // }
          setCurrentPage(twopage);
        } else {
          let currentPage = Math.floor(scrollPos / pageht) + 1;
          setCurrentPage(currentPage);
        }
      }, 100);
    };

    if (ref.current) {
      ref.current.addEventListener("wheel", isScrolling);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("wheel", isScrolling);
      }
      clearTimeout(isScrollingTimeout);
    };
  }, [twoPageView]);

  function scrollIntoView() {
    var highlightElement = document.getElementById(pdfHighlightId);
    if (highlightElement.innerText != "") {
      document.getElementById(pdfHighlightId).scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }

  const navtopage = () => {
    if (currentPage > 0 && currentPage <= numPages) {
      let pageht = pageref.current.clientHeight;
      props.pdfContainer.current.scrollTop = pageht * (currentPage - 1);
      document.activeElement.blur();
    }
  };

  function selectText() {
    props.pdfSearch("");
    props.latexSearch(window.getSelection().toString());
  }

  const twoPageToggle = () => {
    setTwoPageView(!twoPageView);
  };

  function onLoadSuccess({ numPages }) {
    setNumPages(numPages);
    if (props.pdfContainer) {
      setTimeout(
        () => props.pdfContainer.current.scrollTo(0, props.prevScroll),
        500
      );
    }
  }

  return (
    <div className={styles.document_outer}>
      <div className={styles.header}>
        <HStack
          backgroundColor="#454443"
          justifyContent="space-between"
          h="48px"
          width="auto"
          zIndex={10}
        >
          <ButtonGroup spacing="1">
            <Input
              style={{
                display: flexbox,
                textAlign: "center",
                height: "48px",
                width: "48px",
                backgroundColor: "#222",
                color: "#fff",
                display: "flex",
                border: "none",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px",
              }}
              value={twoPageView ? `${currentPage}` : currentPage}
              onChange={(e) => {
                setCurrentPage(e.target.value);
                setTwoPageView(false);
              }}
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  navtopage();
                }
              }}
            ></Input>
            <Box
              style={{
                height: "48px",
                width: "48px",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              of {numPages}
            </Box>
            <Tooltip label="Zoom Out">
              <IconButton
                onClick={() => {
                  scale > 0.05 ? setScale(scale - 0.1) : <></>;
                }}
                color="white"
                size="lg"
                colorScheme="#f47c0d"
                aria-label="Zoom-Out"
                icon={<ZoomOut />}
                _hover={{ bg: "#222" }}
              />
            </Tooltip>
            <Tooltip label="Zoom In">
              <IconButton
                onClick={() => setScale(scale + 0.1)}
                size="lg"
                colorScheme="#f47c0d"
                aria-label="Zoom-In"
                icon={<ZoomIn />}
                _hover={{ bg: "#222" }}
              />
            </Tooltip>
            <Tooltip label="Two page view">
              <IconButton
                onClick={() => twoPageToggle()}
                size="lg"
                colorScheme="#f47c0d"
                aria-label="Text to Italic"
                icon={<TwoPage />}
                bg={twoPageView ? "#222" : "#454443"}
                _hover={{ bg: "#222" }}
              />
            </Tooltip>
          </ButtonGroup>
          {props.pdfFull ? (
            <Tooltip label="Minimize PDF">
              <IconButton
                onClick={() => {
                  props.toggleEditor();
                }}
                size="lg"
                bg="#454443"
                aria-label="Resize pdf"
                icon={<Resize2 />}
                _hover={{ bg: "#222" }}
              ></IconButton>
            </Tooltip>
          ) : (
            <Tooltip label="Maximize PDF">
              <IconButton
                onClick={() => {
                  props.toggleEditor();
                  // setClientWidth(1);
                }}
                size="lg"
                colorScheme="#f47c0d"
                aria-label="Resize pdf"
                icon={<Resize1 />}
                bg="#454443"
                _hover={{ bg: "#222" }}
              ></IconButton>
            </Tooltip>
          )}
        </HStack>
      </div>
      <div className={styles.document} ref={ref}>
        <Document
          file={file}
          onLoadSuccess={onLoadSuccess}
          className={twoPageView ? styles.twopageview : styles.document_viewer}
        >
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page, i) => (
              <div key={i} className={styles.pagedom} ref={pageref}>
                <Page
                  className={styles.viewer}
                  onMouseUp={selectText}
                  pageNumber={page}
                  scale={0.9 + scale}
                  onRenderTextLayerSuccess={scrollIntoView}
                  customTextRenderer={textRenderer}
                  data-page={page}
                  // onPageChange={({ pageNumber }) => onPageChange(pageNumber)}
                />
              </div>
            ))}
        </Document>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pdfsearch: state.pdfsearch.pdfSearch,
});

const mapDispatchToProps = (dispatch) => {
  return {
    latexSearch: (data) => dispatch({ type: "latexSearch", payload: data }),
    pdfSearch: (data) => dispatch({ type: "pdfSearch", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
