import { useEffect, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Image,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from "@chakra-ui/react";
import { MdOutlineMailOutline, MdUploadFile } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getTokenApi, validateTokenApi } from "../api";
import { useToast } from "@chakra-ui/react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [searchParams] = useSearchParams();
  const fileId = searchParams.get("fsp");
  const toast = useToast();
  const navigate = useNavigate();
  const toastdisplayed = new Set();

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("token")) {
        var result = await validateTokenApi();
        if (result.data) {
          navigate("/#" + result.data.file);
        }
      }
    })();
  }, []);

  const login = async () => {
    var result = await getTokenApi(fileId, email);
    if (result.data) {
      if (result.data.token) {
        localStorage.setItem("token", result.data.token);
        navigate("/#" + result.data.file);
      }
    } else {
      if (result.error) {
        if (!toastdisplayed.has("error")) {
          toast({
            id: "login-error",
            title: "Login Failed.",
            description: result.error.response.data.response,
            status: "error",
            duration: null,
            isClosable: true,
            onCloseComplete: () => {
              toastdisplayed.delete("error");
            },
          });
          toastdisplayed.add("error");
        }
      }
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          width="180px"
          height="40px"
          objectFit="cover"
          src={process.env.PUBLIC_URL + "/images/Logo.png"}
          alt="EditLatex"
        />
        <Box minW={{ base: "90%", md: "468px" }}>
          <Stack
            spacing={4}
            p="1rem"
            backgroundColor="whiteAlpha.900"
            boxShadow="md"
          >
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<MdUploadFile color="gray.300" />}
                />
                <Input type="text" placeholder="File ID" value={fileId} />
              </InputGroup>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<MdOutlineMailOutline color="gray.300" />}
                />
                <Input
                  type="text"
                  placeholder="Name / Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <Button
              borderRadius={0}
              type="button"
              variant="solid"
              colorScheme="teal"
              width="full"
              onClick={login}
            >
              Login
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
