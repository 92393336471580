import React, { Component, useEffect, useState } from "react";
import { Box, HStack, Button } from "@chakra-ui/react";
import Corrections from "./Corrections";
import Query from "./Query";
import { render } from "@testing-library/react";
import { color } from "framer-motion";

function LeftPanel(props) {
  const [view, setView] = useState("queries");

  return (
    <Box width="230px" minW="230px" height="100vh" backgroundColor="#F5F5F5">
      <HStack
        px="4px"
        height="50px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid #E8E2DF"
      >
        <Button
          variant="unstyled"
          style={{
            marginTop: "7px",
            color: view == "queries" ? "#F26722" : "black",
            fontWeight: view == "queries" ? "600" : "400",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Roboto, sans-serif",
          }}
          fontWeight="400"
          width="60%"
          onClick={() => setView("queries")}
        >
          Queries
          <hr
            style={{
              marginTop: "18px",
              width: "60%",
              borderColor: view == "queries" ? "#F26722" : "#E8E2DF",
            }}
          ></hr>
        </Button>
        <Button
          variant="unstyled"
          style={{
            marginTop: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: view == "corrections" ? "#F26722" : "black",
            fontWeight: view == "corrections" ? "600" : "400",
            fontFamily: "Roboto, sans-serif",
          }}
          fontWeight="400"
          width="80%"
          onClick={() => setView("corrections")}
        >
          Corrections
          <hr
            style={{
              marginTop: "18px",
              width: "60%",
              borderColor: view == "corrections" ? "#F26722" : "#E8E2DF",
            }}
          ></hr>
        </Button>
      </HStack>
      {view == "corrections" ? (
        <Corrections corrections={props.corrections} ace={props.editorRef} />
      ) : (
        <Query queries={props.queries} ace={props.editorRef} />
      )}
    </Box>
  );
}

export default LeftPanel;
