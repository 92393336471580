import React, { useState } from "react";
import { VStack, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

function CitationPopup(props) {
  return typeof props.name !== undefined ? (
    <VStack align="start">
      <Text>{props.name}</Text>
      <Text>{props.review}</Text>
    </VStack>
  ) : (
    <VStack align="start">
      <Text>Reference not found</Text>
      {/* <Text>{props.review}</Text> */}
    </VStack>
  );
}

export default CitationPopup;
