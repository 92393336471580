import {
  Box,
  Button,
  Divider,
  Input,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const Help = ({ isOpen, onClose, setChange, change }) => {
  const [shortcuts, setShortcuts] = useState(() => {
    const data = localStorage.getItem("shortcuts");
    return data
      ? JSON.parse(data)
      : {
          shortcut1: "\\looseness-1",
          shortcut2: "\\vfill\\eject",
          shortcut3: "\\unskip\\break",
          shortcut4: "",
          shortcut5: "",
          shortcut6: "",
        };
  });

  useEffect(() => {
    localStorage.setItem("shortcuts", JSON.stringify(shortcuts));
  }, [shortcuts]);

  const inputChange = (e, id) => {
    let updatedscut = { ...shortcuts };
    updatedscut[`shortcut${id}`] = e.target.value;
    setShortcuts(updatedscut);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent maxH="500px">
        <ModalHeader>Help</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="scroll">
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">Search</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>F</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">Replace</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>H</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">Undo</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>Z</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">Redo</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>Y</Kbd>
            </span>
          </Box>
          <Divider mb="3" />
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">Bold</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>B</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">Italic</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>I</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">toUpperCase</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>U</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">toLowerCase</Text>
            <span>
              <Kbd>Shift</Kbd> + <Kbd>U</Kbd>
            </span>
          </Box>
          <Divider mb="3" />
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">/textbf</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>B</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">/textit</Text>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>I</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Text paddingLeft="1px">/nobreak</Text>
            <span>
              <Kbd>Alt</Kbd> + <Kbd>B</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Box display="flex" alignItems="center" width="auto">
              <Input
                style={{
                  height: "25px",
                  width: "200px",
                  padding: "2px",
                }}
                placeholder="Add a shortcut"
                value={shortcuts.shortcut1}
                onChange={(e) => inputChange(e, 1)}
              ></Input>
            </Box>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>1</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Box display="flex" alignItems="center" width="auto">
              <Input
                style={{
                  height: "25px",
                  width: "200px",
                  padding: "2px",
                }}
                placeholder="Add a shortcut"
                value={shortcuts.shortcut2}
                onChange={(e) => inputChange(e, 2)}
              ></Input>
            </Box>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>2</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Box display="flex" alignItems="center" width="auto">
              <Input
                style={{
                  height: "25px",
                  width: "200px",
                  padding: "2px",
                }}
                placeholder="Add a shortcut"
                value={shortcuts.shortcut3}
                onChange={(e) => inputChange(e, 3)}
              ></Input>
            </Box>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>3</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Box display="flex" alignItems="center" width="auto">
              <Input
                style={{
                  height: "25px",
                  width: "200px",
                  padding: "2px",
                }}
                placeholder="Add a shortcut"
                value={shortcuts.shortcut4}
                onChange={(e) => inputChange(e, 4)}
              ></Input>
            </Box>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>4</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Box display="flex" alignItems="center" width="auto">
              <Input
                style={{
                  height: "25px",
                  width: "200px",
                  padding: "2px",
                }}
                placeholder="Add a shortcut"
                value={shortcuts.shortcut5}
                onChange={(e) => inputChange(e, 5)}
              ></Input>
            </Box>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>5</Kbd>
            </span>
          </Box>
          <Box display="flex" justifyContent="space-between" mb="3">
            <Box display="flex" alignItems="center" width="auto">
              <Input
                style={{
                  height: "25px",
                  width: "200px",
                  padding: "2px",
                }}
                placeholder="Add a shortcut"
                value={shortcuts.shortcut6}
                onChange={(e) => inputChange(e, 6)}
              ></Input>
            </Box>
            <span>
              <Kbd>Ctrl</Kbd> + <Kbd>6</Kbd>
            </span>
          </Box>
          {/* <Button onClick={() => setChange(!change)}>Update</Button> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Help;
