export const SaveIcon = () => {
  return (
    <img
      alt="save-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/save.svg"}
    />
  );
};

export const SearchIcon = () => {
  return (
    <img
      alt="Search-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/Search.svg"}
    />
  );
};

export const ReplaceIcon = () => {
  return (
    <img
      alt="Replace-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/Replace.svg"}
    />
  );
};

export const MathEqn = () => {
  return (
    <img
      alt="Matheqn-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/MathEqn.svg"}
    />
  );
};

export const BoldIcon = () => {
  return (
    <img
      alt="Bold-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/BoldIcon.svg"}
    />
  );
};

export const ItalicIcon = () => {
  return (
    <img
      alt="Italic-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/ItalicsIcon.svg"}
    />
  );
};

export const Auto = () => {
  return (
    <img
      alt="Autocorrect-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/AutoCorrectIcon.svg"}
    />
  );
};

export const Resize1 = () => {
  return (
    <img
      alt="Resize-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/Resize1.svg"}
    />
  );
};

export const Resize2 = () => {
  return (
    <img
      alt="Resize-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/Resize2.svg"}
    />
  );
};

export const MenuBar = () => {
  return (
    <img
      alt="Resize-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/MenuBar.svg"}
    />
  );
};

export const MenuClose = () => {
  return (
    <img
      alt="Resize-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/closeIcon.svg"}
    />
  );
};

export const ZoomOut = () => {
  return (
    <img
      alt="Resize-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/zoomOut.svg"}
    />
  );
};

export const ZoomIn = () => {
  return (
    <img
      alt="Resize-icon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/zoomIn.svg"}
    />
  );
};

export const TwoPage = () => {
  return (
    <img
      alt="twopageicon"
      src={process.env.PUBLIC_URL + "/images/Redesignpics/twoPageview.svg"}
    />
  );
};
