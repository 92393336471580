import React, { useContext, useState } from "react";
import { VStack, Box, Heading, Text, Code } from "@chakra-ui/react";
import { connect } from "react-redux";

const ErrorLog = (props) => {
  return (
    <VStack>
      {props.data && props.data.length ? (
        props.data.map((i, j) => (
          <Box
            cursor="pointer"
            // onClick={() =>
            //   props.ace.editor.current.setSelection({
            //     start: { row: i.line, column: i.col },
            //     end: { row: i.line, column: i.col + i.text.length },
            //   })
            // }
            onClick={() => {
              props.selection({
                start: { row: i.line, column: i.col },
                end: { row: i.line, column: i.col + i.text.length },
              });
            }}
            w="99%"
            p={5}
            shadow="md"
            borderWidth="1px"
          >
            <Heading fontSize="md">{i.description}</Heading>
            <Text mt={2}>{i.text}</Text>
            <Code
              colorScheme="red"
              children={"Line no." + (i.line + 1) + ":" + i.col}
            />
          </Box>
        ))
      ) : (
        <Heading>Nothing Found</Heading>
      )}
    </VStack>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    selection: (data) => dispatch({ type: "toolSearch", payload: data }),
  };
};

export default connect(null, mapDispatchToProps)(ErrorLog);
