import logo from "./logo.svg";
import styles from "./App.module.css";
import Ace from "./components/Ace";
import React, {
  StrictMode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import Header from "./components/Header";
import RightPanel from "./components/RightPanel";
import LeftPanel from "./components/LeftPanel";
import RightBar from "./components/RightBar";
import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";
import { regexFinderTool } from "./utils";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { getNormalPdfApi } from "./api";
import SplitPane, { SplitPaneLeft, Divider } from "./SplitPane";
import AceFunctional from "./components/AceFunctional";

function App() {
  const [rightController, setRightController] = useState({
    view: "pdf",
    pdfData: "",
    errorData: "",
    rawData: "",
  });
  const [filename, setFileName] = useState("");
  const [leftController, setLeftController] = useState(true);
  const [pdfviewer, setPdfViewer] = useState(true);
  const [pdfFull, setPdfFull] = useState(false);
  const [parentWidth, setParentWidth] = useState();
  const [change, setChange] = useState(false);
  const ace = useRef();
  const pdfContainer = useRef();
  const parentdiv = useRef();

  const leftpanelforsplit = leftController.toString();
  const pdfviewerforsplit = pdfviewer.toString();
  const pdffullforsplit = pdfFull.toString();

  const toggleLeftPanel = () =>
    setLeftController((leftController) => !leftController);

  const togglePDF = () => {
    setPdfViewer(!pdfviewer);
  };

  const toggleEditor = () => {
    setPdfFull(!pdfFull);
  };

  const [scrollPos, setScrollPos] = useState(0);

  const toast = useToast();

  useEffect(() => {
    let fullwidth = parentdiv.current.getBoundingClientRect().width;
    setParentWidth(fullwidth);
    // console.log(fullwidth);
  });

  useEffect(() => {
    (async () => {
      var result = await getNormalPdfApi();
      if (result.data) {
        setRightController((prevState) => ({
          ...prevState,
          view: "pdf",
          pdfData: "data:application/pdf;base64," + result.data.pdf,
        }));
      }
    })();
  }, []);

  const processTools = function (jsonData) {
    var result = regexFinderTool(
      ace.current.editor.session.doc.$lines,
      jsonData
    );

    setRightController((prevState) => ({
      ...prevState,
      view: "error",
      errorData: result,
    }));
    return true;
  };
  const scrollTo = () => {
    let scrollpos = pdfContainer.current.scrollTop;
    setScrollPos(scrollpos);
  };

  // console.log(scrollPos);
  // useEffect(() => {
  //   pdfContainer.current.addEventListener("scroll", scrollTo);

  //   return () => {
  //     pdfContainer.current.removeEventListener("scroll", scrollTo);
  //   };
  // }, []);

  return (
    <div className={styles.App}>
      <div className={styles.header}>
        <Header
          editorRef={ace}
          processTools={processTools}
          pdfContainer={pdfContainer}
          filename={filename}
          setRightController={setRightController}
          setChange={setChange}
          change={change}
        />
      </div>
      <div className={styles.container} ref={parentdiv}>
        {leftController ? <LeftPanel editorRef={ace} /> : null}

        <SplitPane
          className={styles.split_pane}
          pdffull={pdffullforsplit}
          pdfviewer={pdfviewerforsplit}
          leftcontroller={leftpanelforsplit}
          parentwidth={parentWidth}
        >
          <SplitPaneLeft className={styles.leftpane}>
            <div className={styles.eceEditor}>
              {/* <Ace
                ref={ace}
                updateCorrections={setCorrections}
                updateQueries={setQueries}
                toast={toast}
                toggleLeftPanel={toggleLeftPanel}
                leftController={leftController}
                togglePDF={togglePDF}
                pdfviewer={pdfviewer}
                pdfFull={pdfFull}
              /> */}

              <AceFunctional
                ref={ace}
                toast={toast}
                toggleLeftPanel={toggleLeftPanel}
                leftController={leftController}
                togglePDF={togglePDF}
                setFileName={setFileName}
                pdfviewer={pdfviewer}
                pdfFull={pdfFull}
                change={change}
                setChange={setChange}
              />
            </div>
          </SplitPaneLeft>

          <Divider />

          <div
            className={`${styles.pdfViewer} ${styles.rightpane}`}
            ref={pdfContainer}
          >
            <RightPanel
              editorRef={ace}
              {...rightController}
              pdfContainer={pdfContainer}
              toggleEditor={toggleEditor}
              pdfFull={pdfFull}
              scrollPos={scrollPos}
            />
          </div>
        </SplitPane>

        <div className={styles.RightBar}>
          <RightBar control={setRightController} />
        </div>
      </div>
    </div>
  );
}

export default App;
