import React, { useEffect, useRef } from 'react';
import { VStack, Text } from '@chakra-ui/react'
import { MathfieldElement } from 'mathlive';

function MathPopup(props) {
    const parent = useRef(null);
    const removeUnwantedFormulas = (inp) => {
        inp = inp.replace(/\\label\{(.*?)\}/g, "")
        inp = inp.replace("\\cal", "")
        inp = inp.replace("\\un", "")
        return inp
    }
    useEffect(() => {
        var mfe = new MathfieldElement({readOnly:true});
        mfe.value = removeUnwantedFormulas(props.equation);
        window.r = mfe;
        parent.current.replaceChildren(mfe);
    });
    return (
        <VStack fontSize="xl" ref={parent}></VStack>
    );
}

export default MathPopup;
