import styles from "./Header.module.css";
import React, { useContext, useState } from "react";
import {
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  ButtonGroup,
  Spacer,
  Flex,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, SpinnerIcon } from "@chakra-ui/icons";
import CeTools from "./CeTools";
import PeTools from "./PeTools";
import { useToast } from "@chakra-ui/react";
import { downloadFile, downloadURI } from "../utils";
import { downloadTexApi } from "../api";
import { Spinner } from "@chakra-ui/react";
import {
  saveTexApi,
  compileNormalTexApi,
  getNormalPdfApi,
  deleteTokenApi,
  compileTrackTexApi,
  getXmlApi,
  getProofPdfApi,
  getTrackPdfApi,
  getWebPdfApi,
  getPrintPdfApi,
  submitFileApi,
} from "../api";
import { useNavigate } from "react-router-dom";
import Imageupload from "./Imageupload";
import { color } from "framer-motion";
import { TbQuestionMark } from "react-icons/tb";
import Help from "./Help";
import { connect } from "react-redux";

function Header(props) {
  // const context = useContext(SearchContext);

  const {
    isOpen: HelpOpen,
    onToggle: onHelpToogle,
    onClose: onHelpClose,
  } = useDisclosure();
  const {
    isOpen: imageUploadOpen,
    onToggle: onImageUploadToogle,
    onClose: onImageUploadClose,
  } = useDisclosure();
  const {
    isOpen: isCEToolsOpen,
    onToggle: onCEToolsToggle,
    onClose: onCEToolsClose,
  } = useDisclosure();
  const {
    isOpen: isPEToolsOpen,
    onToggle: onPEToolsToggle,
    onClose: onPEToolsClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const downloadTex = async () => {
    toast({
      title: "Downloading",
      description: "Downloading Tex",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });
    var result = await downloadTexApi();
    toast.closeAll();
    if (result.data) {
      downloadFile(result.data.tex, result.data.file + ".tex", "text/plain");
      // downloadFile("data:text/plain;charset=utf-8," + result.data.tex.replace(/\n/g, "%0D%0A"), result.data.file + ".tex")
      toast({
        title: "Downloading Tex.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Downloading Tex Error",
        description: "Error in downloading Tex contact technical team",
        status: "error",
        duration: 2000,
      });
    }
  };

  const downloadNormalPdf = async () => {
    toast({
      title: "Downloading",
      description: "Downloading PDF",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });
    var result = await getNormalPdfApi();
    toast.closeAll();
    if (result.data) {
      downloadURI(
        "data:application/pdf;base64," + result.data.pdf,
        result.data.file + ".pdf"
      );
      toast({
        title: "Downloading PDF.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Download PDF Error",
        description: "Error in downloding PDF contact technical team.",
        status: "error",
        duration: 2000,
      });
    }
  };

  const downloadProofPdf = async () => {
    toast({
      title: "Downloading",
      description: "Downloading Proof PDF",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });

    var result = await getProofPdfApi();
    toast.closeAll();
    if (result.data) {
      downloadURI(
        "data:application/pdf;base64," + result.data.pdf,
        result.data.file + ".pdf"
      );
      toast({
        title: "Downloading Proof PDF.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Downloading ProofPDF error",
        description: "Error in Downloading ProofPDF contact technical team",
        status: "error",
        duration: 2000,
      });
    }
  };

  const downloadTrackPdf = async () => {
    toast({
      title: "Downloading",
      description: "Downloading Track PDF",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });
    var result = await getTrackPdfApi();
    toast.closeAll();
    if (result.data) {
      downloadURI(
        "data:application/pdf;base64," + result.data.pdf,
        result.data.file + "_track.pdf"
      );
      toast({
        title: "Downloading Track PDF.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Downloading Track Error.",
        description: "Kindly compile with track before downloading.",
        status: "error",
        duration: 2000,
      });
    }
  };

  const downloadPrintPdf = async () => {
    toast({
      title: "Downloading",
      description: "Downloading Print PDF.",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });
    var result = await getPrintPdfApi();
    toast.closeAll();
    if (result.data) {
      let filename = result.data.file.replace("_print", "");
      downloadURI(
        "data:application/pdf;base64," + result.data.pdf,
        filename + "-print.pdf"
      );
      toast({
        title: "Downloading Print PDF.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Downloading Print PDF Error.",
        description:
          "Please test with normal compilation, before compiling for web / print.",
        status: "error",
        duration: 2000,
      });
    }
  };

  const downloadWebPdf = async () => {
    toast({
      title: "Downloading",
      description: "Downloading Web PDF.",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });
    var result = await getWebPdfApi();
    toast.closeAll();
    if (result.data) {
      let filename = result.data.file.replace("_web", "");
      downloadURI(
        "data:application/pdf;base64," + result.data.pdf,
        filename + ".pdf"
      );
      toast({
        title: "Downloading Web PDF.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Downloading Web PDF Error.",
        description:
          "Please test with normal compilation, before compiling for web / print.",
        status: "error",
        duration: 2000,
      });
    }
  };

  const downloadXML = async () => {
    toast({
      title: "Downloading.",
      description: "Downloading XML.",
      status: "info",
      duration: null,
      icon: (
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="blue.500"
          size="sm"
        />
      ),
    });
    var result = await getXmlApi();
    toast.closeAll();
    if (result.data) {
      downloadURI(
        "data:text/xml;charset=utf-8," +
          result.data.xml.replace(/\n/g, "%0D%0A"),
        result.data.file + ".xml"
      );
      toast({
        title: "Downloading XML.",
        description: "Download completed.",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Downloading XML Error.",
        description: "Error in downloading XML please contact technical team.",
        status: "error",
        duration: 2000,
      });
    }
  };

  const logout = async () => {
    var result = await deleteTokenApi();
    if (result.data) {
      toast({
        title: "Logout Successfull.",
        description: "Current session has been logged out.",
        status: "success",
        duration: 2000,
      });
      localStorage.setItem("token", null);
      navigate("/login");
    }
  };

  const submitTex = async () => {
    var result = await saveTexApi(props.tex, props.correction);
    if (result.data) {
      toast({
        title: "Saving Tex.",
        description: "Save successfull.",
        status: "success",
        duration: 1000,
      });
      toast({
        title: "Submit",
        description: "Submitting the file for further processing.",
        status: "info",
        duration: null,
        icon: (
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
          />
        ),
      });
      var res = await submitFileApi();
      if (res.data) {
        toast.closeAll();
        toast({
          title: "Success.",
          description: "Compilation successfull.",
          status: "success",
          duration: 2000,
        });
        await deleteTokenApi();
        localStorage.setItem("token", null);
        navigate("/login");
      } else {
        console.log(res.error.response.data.log);
        toast.closeAll();
        toast({
          title: "Error.",
          description: "Error in compilation, please check log file.",
          status: "error",
          duration: 2000,
        });
      }
    }
  };

  const compileTex = async () => {
    if (props.pdfContainer) {
      var prevScroll = props.pdfContainer.current.scrollTop;
    } else {
      var prevScroll = 0;
    }
    var result = await saveTexApi(
      // props.editorRef.current.state.text,
      // props.editorRef.current.state.track
      // context.texFile,
      // context.corrections
      props.tex,
      props.correction
    );
    if (result.data) {
      toast({
        title: "Saving Tex.",
        description: "Save successfull.",
        status: "success",
        duration: 1000,
      });
      toast({
        title: "Compiling",
        description: "Compiling normal tex document.",
        status: "info",
        duration: null,
        icon: (
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
          />
        ),
      });
      var res = await compileNormalTexApi();
      if (res.data) {
        toast.closeAll();
        toast({
          title: "Success.",
          description: "Compilation successfull.",
          status: "success",
          duration: 2000,
        });
        props.setRightController((prevState) => ({
          ...prevState,
          view: "pdf",
          pdfData: "data:application/pdf;base64," + res.data.pdf,
          prevScroll: prevScroll,
        }));
      } else {
        console.log(res.error.response.data.log);
        toast.closeAll();
        toast({
          title: "Error.",
          description: "Error in compilation, please check log file.",
          status: "error",
          duration: 2000,
        });
        props.setRightController((prevState) => ({
          ...prevState,
          view: "raw",
          rawData: res.error.response.data.log,
        }));
      }
    }
  };

  const compileTrackTex = async () => {
    if (props.pdfContainer) {
      var prevScroll = props.pdfContainer.current.scrollTop;
    } else {
      var prevScroll = 0;
    }
    var result = await saveTexApi(props.tex, props.correction);
    if (result.data) {
      toast({
        title: "Saving Tex.",
        description: "Save successfull.",
        status: "success",
        duration: 1000,
      });
      toast({
        title: "Compiling",
        description: "Compiling track tex document.",
        status: "info",
        duration: null,
        icon: (
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="blue.500"
            size="sm"
          />
        ),
      });
      var res = await compileTrackTexApi();
      toast.closeAll();
      if (res.data) {
        toast({
          title: "Success.",
          description: "Compilation successfull.",
          status: "success",
          duration: 2000,
        });
        props.setRightController((prevState) => ({
          ...prevState,
          view: "pdf",
          pdfData: "data:application/pdf;base64," + res.data.pdf,
          prevScroll: prevScroll,
        }));
      } else {
        toast({
          title: "Error.",
          description: "Error in compilation, please check log file.",
          status: "error",
          duration: 2000,
        });
        props.setRightController((prevState) => ({
          ...prevState,
          view: "raw",
          rawData: res.error.response.data.error,
        }));
      }
    }
  };

  // display the filename with ... after certain length.
  const getfilename = () => {
    let filenamedisplay;

    if (props.filename.length > 15) {
      return (filenamedisplay = `${props.filename.substring(0, 15)}...`);
    } else {
      return (filenamedisplay = props.filename);
    }
  };

  return (
    <Flex minWidth="max-content" height="80px" alignItems="center" gap={2}>
      <Box ml={5} width="144px" height="60px">
        <a href="http://www.editlatex.com" target="_">
          <Image
            width="144px"
            height="32px"
            objectFit="contain"
            src={process.env.PUBLIC_URL + "/images/Logo.png"}
            alt="EditLatex"
          />
        </a>
        <Box display="flex" justifyContent="space-evenly" alignItems="center">
          <Text
            height="12px"
            width="65px"
            fontSize="10px"
            fontFamily="Roboto, sans-serif"
            color="#222"
            textAlign="center"
          >
            Powered by
          </Text>
          <a href="https://www.transforma.in/" target="_">
            <Image
              cursor="pointer"
              alt="transforma logo"
              src={
                process.env.PUBLIC_URL +
                "/images/Redesignpics/transforma_logo_img.svg"
              }
            />
          </a>
        </Box>
      </Box>
      <Box
        height="48px"
        width="fit-content"
        marginLeft="76px"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        borderRadius="24px"
        backgroundColor="#E6EAEC"
        p="5px"
      >
        <Box
          height="36px"
          width="36px"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#fff"
        >
          <Image
            src={
              process.env.PUBLIC_URL + "/images/Redesignpics/filenameicon.svg"
            }
          ></Image>
        </Box>
        <Text height="auto" width="fit-content" p="5px">
          {getfilename()}
        </Text>
      </Box>
      <Spacer />
      <ButtonGroup gap="4" mr="4" zIndex={10}>
        <Menu>
          <MenuButton
            variant="unstyled"
            as={Button}
            border={"none"}
            background={"#fff"}
            rightIcon={<ChevronDownIcon />}
            color="#222"
            padding="0 4px"
            fontSize="lg"
            fontFamily="Roboto, sans-serif"
            fontWeight={500}
            display="flex"
            alignItems="center"
            _hover={{ backgroundColor: "#edf2f7" }}
          >
            Tools
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() =>
                toast({
                  title: "Feature yet to be implemented",
                  duration: 2000,
                })
              }
            >
              Tex-Validation
            </MenuItem>
            <MenuItem onClick={onPEToolsToggle}>PE Tools</MenuItem>
            <PeTools
              processTools={props.processTools}
              isOpen={isPEToolsOpen}
              onClose={onPEToolsClose}
            />
            <MenuItem onClick={onCEToolsToggle}>CE Tools</MenuItem>
            <CeTools
              processTools={props.processTools}
              isOpen={isCEToolsOpen}
              onClose={onCEToolsClose}
            />
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            variant="unstyled"
            as={Button}
            background={"#fff"}
            rightIcon={<ChevronDownIcon />}
            fontSize="lg"
            color="#222"
            padding="0 4px"
            fontFamily="Roboto, sans-serif"
            fontWeight={500}
            display="flex"
            alignItems="center"
            _hover={{ backgroundColor: "#edf2f7" }}
          >
            Upload
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onImageUploadToogle}>Upload</MenuItem>
            <Imageupload
              // uploadImage={uploadImage}
              isOpen={imageUploadOpen}
              onClose={onImageUploadClose}
            />
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            variant="unstyled"
            as={Button}
            background={"#fff"}
            rightIcon={<ChevronDownIcon />}
            fontSize="lg"
            color="#222"
            padding="0 4px"
            fontFamily="Roboto, sans-serif"
            fontWeight={500}
            display="flex"
            alignItems="center"
            _hover={{ backgroundColor: "#edf2f7" }}
          >
            Compile
          </MenuButton>
          <MenuList>
            <MenuItem onClick={compileTex}>Latex</MenuItem>
            <MenuItem onClick={compileTrackTex}>Track Latex</MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            variant="unstyled"
            as={Button}
            background={"#fff"}
            rightIcon={<ChevronDownIcon />}
            fontSize="lg"
            color="#222"
            padding="0 4px"
            fontFamily="Roboto, sans-serif"
            fontWeight={500}
            display="flex"
            alignItems="center"
            _hover={{ backgroundColor: "#edf2f7" }}
          >
            Download
          </MenuButton>
          <MenuList>
            <MenuItem onClick={downloadTex}>Tex</MenuItem>
            {/* <MenuItem>Track Tex</MenuItem> */}
            <MenuItem onClick={downloadXML}>XML</MenuItem>
            <MenuItem onClick={downloadNormalPdf}>PDF</MenuItem>
            <MenuItem onClick={downloadTrackPdf}>Track PDF</MenuItem>
            {/* <MenuItem onClick={downloadProofPdf}>Proof PDF</MenuItem> */}
            <MenuItem onClick={downloadWebPdf}>Web PDF</MenuItem>
            <MenuItem onClick={downloadPrintPdf}>Print PDF</MenuItem>
          </MenuList>
        </Menu>
        <Button
          colorScheme="white"
          width="103px"
          fontSize="lg"
          fontWeight={400}
          border="1px"
          borderRadius="4px"
          borderColor="black"
          color="black"
          _hover={{ bg: "black", color: "white" }}
          onClick={submitTex}
        >
          Submit
        </Button>

        {HelpOpen ? (
          <Button
            variant="unstyled"
            backgroundColor="white"
            display="flex"
            onClick={onHelpToogle}
          >
            <img
              style={{ height: "60px", width: "60px" }}
              alt="Help-icon"
              src={process.env.PUBLIC_URL + "/images/Redesignpics/HelpOpen.svg"}
            />
          </Button>
        ) : (
          <Button
            variant="unstyled"
            backgroundColor="white"
            display="flex"
            onClick={onHelpToogle}
          >
            <img
              style={{ height: "60px", width: "60px" }}
              alt="Help-icon"
              src={process.env.PUBLIC_URL + "/images/Redesignpics/Help.svg"}
            />
          </Button>
        )}
        <Help
          isOpen={HelpOpen}
          onClose={onHelpClose}
          setChange={props.setChange}
          change={props.change}
        />
        <Button variant="unstyled" colorScheme="white" onClick={logout}>
          <img
            alt="logout-icon"
            src={process.env.PUBLIC_URL + "/images/Redesignpics/logout.svg"}
          />
        </Button>
        {/* <Button colorScheme='gray'>Reset</Button> */}
      </ButtonGroup>
    </Flex>
  );
}

const mapStateToProps = (state) => ({
  tex: state.uploadTex.tex,
  correction: state.correction.corrections,
});

export default connect(mapStateToProps, null)(Header);
