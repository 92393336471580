import React from "react";
import styles from "./FooterNew.module.css";
import { Link } from "react-router-dom";
const FooterNew = () => {
  return (
    <div className={styles.footer_outer}>
      <div className={styles.leftsection}>
        <div className={styles.logo}>
          <img
            alt="EditLatex logo"
            src={
              process.env.PUBLIC_URL + "/images/Redesignpics/EditLatexlogo.png"
            }
          ></img>
        </div>
        <div className={styles.wordlink}>
          <a href="">Terms & Conditions</a>
          <a href="">Privacy Policy</a>
        </div>
      </div>
      <div className={styles.rightsection}>
        <div className={styles.sociallogo}>
          <Link to="https://www.facebook.com/www.transforma.in" target="_">
            <div className={styles.logocircle}>
              <img
                className={styles.logoimg}
                alt="facebook-icon"
                src={
                  process.env.PUBLIC_URL + "/images/Redesignpics/Facebook.svg"
                }
              ></img>
            </div>
          </Link>
          {/* <Link>
            <img
              className={styles.logoimg}
              alt="instagram-icon"
              src={
                process.env.PUBLIC_URL + "/images/Redesignpics/Instagram.svg"
              }
            ></img>
          </Link> */}
          <Link
            to="https://twitter.com/i/flow/login?redirect_after_login=%2Ftransformapress"
            target="_"
          >
            <div className={styles.logocircle}>
              <img
                className={styles.logoimg}
                alt="twitter-icon"
                src={
                  process.env.PUBLIC_URL + "/images/Redesignpics/twitter.svg"
                }
              ></img>
            </div>
          </Link>
          <Link
            to="https://www.linkedin.com/company/transforma-private-limited"
            target="_"
          >
            <div className={styles.logocircle}>
              <img
                className={styles.logoimg}
                alt="linkedin-icon"
                src={
                  process.env.PUBLIC_URL + "/images/Redesignpics/linkedin.svg"
                }
              ></img>
            </div>
          </Link>
        </div>
        <div className={styles.trademark}>
          <p>©2023 EditLatex</p>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
