import React, { useEffect, useState } from "react";
// import { AbsoluteCenter, Box, Flex, Image, chakra } from "@chakra-ui/react";
import PageHeader from "./pagecomponents/PageHeader";
import styles from "./Home.module.css";
import MainPoster from "./pagecomponents/MainPoster";
import Whoweare from "./pagecomponents/Whoweare";
import { Outlet, useParams } from "react-router-dom";
// import { Page } from "react-pdf";
import FAQ from "./FAQ";
import About2 from "./pagecomponents/About2";
import Feature1 from "./pagecomponents/Feature1";
import ClientCarousel from "./pagecomponents/ClientCarousel";
import GetInTouch from "./pagecomponents/GetInTouch";
// import FormNew from "./pagecomponents/Form";
import FooterNew from "./pagecomponents/FooterNew";

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 900);
  const [section, setSection] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const { page } = useParams();

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    setSection(sections);

    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [page]);

  const updateSize = () => {
    setIsMobile(window.innerWidth < 600);
    setIsTablet(window.innerWidth < 900);
  };

  console.log(menuOpen);
  // window.onbeforeunload = function () {
  //   window.scrollTo(0, 0);
  // };

  // window.onresize = function (event) {
  //   document.location.reload(true);
  // };

  // const Currentpage = ({ page }) => {
  //   switch (page) {
  //     case "advantages":
  //       return <Advantages isMobile={isMobile} />;
  //       break;
  //     case "publishers":
  //       return <Publishers isMobile={isMobile} />;
  //       break;
  //     case "keyaspects":
  //       return <KeyAspects isMobile={isMobile} />;
  //       break;
  //     case "uniquefeatures":
  //       return <Uniquefeatures isMobile={isMobile} />;
  //       break;
  //     case "FAQ":
  //       return <FAQ isMobile={isMobile} />;
  //     case "contact":
  //       return <ContactPage isMobile={isMobile} />;
  //     default:
  //       <Home />;
  //       break;
  //   }
  // };

  return (
    <div className={styles.outer_container}>
      <nav className={styles.main_header}>
        <PageHeader
          section={section}
          isMobile={isMobile}
          isTablet={isTablet}
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
        />
      </nav>

      {/* {page ? (
        <Currentpage page={page} />
      ) : (
        <> */}
      {menuOpen ? <div className={styles.overlay}></div> : <></>}
      <section className={styles.main_poster} id="home">
        <MainPoster />
      </section>
      <section className={styles.about_container} id="about">
        <About2 isMobile={isMobile} />
      </section>
      <section className={styles.service_container} id="features">
        <Feature1 isMobile={isMobile} isTablet={isTablet} />
      </section>
      <section className={styles.who_we_are}>
        <Whoweare isMobile={isMobile} />
      </section>
      <section className={styles.connect}>
        <ClientCarousel isMobile={isMobile} isTablet={isTablet} />
      </section>
      <section className={styles.connect} id="contact">
        <GetInTouch isMobile={isMobile} isTablet={isTablet} />
      </section>
      <section className={styles.contact}>
        <FAQ isMobile={isMobile} />
      </section>
      <section className={styles.footer}>
        <FooterNew isMobile={isMobile} />
      </section>
    </div>
  );
};

export default Home;
