import React, { useEffect, useState, useRef } from "react";
import styles from "../pagecomponents/PageHeader.module.css";
import { VscMenu } from "react-icons/vsc";
import { HamburgerIcon, Icon } from "@chakra-ui/icons";
import { CloseIcon } from "@chakra-ui/icons";
import { HashLink as Link } from "react-router-hash-link";
import { AiFillLinkedin } from "react-icons/ai";
import { GrClose, GrFormClose } from "react-icons/gr";

const PageHeader = ({ section, isMobile, isTablet, setMenuOpen, menuOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(true);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [list, setList] = useState([]);
  const ref = useRef();

  useEffect(() => {
    isTablet ? setValue(-40) : setValue(-70);

    let list = document.querySelectorAll(`.${styles.link}`);
    setList(list);
  }, []);

  const handleClick = (e) => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  window.onscroll = () => {
    var current = "";
    scrollfunction();

    section.forEach((section) => {
      const sectionTop = section.offsetTop - 110;
      if (window.scrollY >= sectionTop) {
        current = section.getAttribute("id");
      }
    });

    list.forEach((li) => {
      li.classList.remove(styles.act);
      li.classList.add(styles.def);
      if (li.id.toLowerCase() === current) {
        li.classList.remove(styles.def);
        li.classList.add(styles.act);
      }
    });
  };

  const scrollfunction = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      // console.log(show);
      setShow(true);
    } else {
      console.log(show);
      setShow(false);
    }
  };

  const handleup = () => {
    console.log("clicked");
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handlechange = (e) => {
    list.forEach((el) => {
      if (e.target.id == e.id) {
        el.classList.remove(styles.def);
        el.classList.add(styles.act);
      } else {
        el.classList.add(styles.def);
      }
    });
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = value;
    window.scrollTo({ top: yCoordinate + yOffset });
  };

  return (
    <>
      <div className={styles.header_outer} id="header">
        {/* Logo */}
        <div className={styles.img_outer}>
          <Link to="#home">
            <img
              alt="editLatex-logo"
              className={styles.img_logo}
              src={process.env.PUBLIC_URL + "/images/Logo.png"}
            />
          </Link>
        </div>
        {/* header link tabs */}
        <nav className={styles.nav_outer}>
          <div className={`${styles.link_outer} `} onClick={handlechange}>
            <Link
              scroll={(el) => {
                scrollWithOffset(el);
              }}
              to="#home"
              className={`${styles.link} ${styles.act}`}
              id="Home"
            >
              <p>Home</p>
              <span className={styles.dot}></span>
            </Link>
            <Link
              scroll={(el) => {
                scrollWithOffset(el);
              }}
              to="#about"
              className={`${styles.link} ${styles.def}`}
              id="About"
            >
              <p>About</p>
              <span className={styles.dot}></span>
            </Link>
            <Link
              scroll={(el) => {
                scrollWithOffset(el);
              }}
              to="#features"
              className={`${styles.link} ${styles.def}`}
              id="Features"
            >
              <p>Features</p>
              <span className={styles.dot}></span>
            </Link>
            <Link
              scroll={(el) => {
                scrollWithOffset(el);
              }}
              to="#contact"
              className={`${styles.link} ${styles.def}`}
              id="Contact"
            >
              <p>Contact</p>
              <span className={styles.dot}></span>
            </Link>
          </div>
        </nav>
        {isTablet ? (
          <></>
        ) : (
          <>
            <div className={styles.trynow}>
              <Link
                to="https://editlatex.transforma.in/editlatex_react/tryit"
                target="_"
              >
                Try now !
              </Link>
            </div>
            <div className={styles.img_outer1}>
              <Link to="https://www.transforma.in/" target="_">
                <img
                  alt="Transforma-logo"
                  className={styles.img_logo}
                  src={
                    process.env.PUBLIC_URL +
                    "/images/Redesignpics/TransformaLogo.png"
                  }
                />
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link
                to="https://www.linkedin.com/company/transforma-private-limited"
                target="_"
              >
                <Icon as={AiFillLinkedin} color="blue" boxSize={8} />
              </Link>
            </div>
          </>
        )}
        {show ? (
          <div className={styles.uparrow} onClick={handleup}>
            <img
              src={process.env.PUBLIC_URL + "/images/Redesignpics/upicon.png"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* for mobile view */}
      <div className={styles.mobile_outer}>
        <div className={styles.menu_icon} onClick={handleClick}>
          {isOpen ? <GrClose size={20} /> : <HamburgerIcon fontSize={24} />}
        </div>
        <div
          className={
            isOpen && isTablet ? `${styles.menu_list}` : `${styles.hide}`
          }
        >
          <nav>
            <ul className={styles.list} ref={ref}>
              <Link
                to="#home"
                scroll={(el) => {
                  scrollWithOffset(el);
                }}
                className={styles.list_items}
                id="Home"
                onClick={() => setIsOpen(false)}
              >
                Home
              </Link>
              <Link
                scroll={(el) => {
                  scrollWithOffset(el);
                }}
                to="#about"
                className={styles.list_items}
                id="About"
                onClick={() => setIsOpen(false)}
              >
                About
              </Link>
              <Link
                to="#features"
                scroll={(el) => {
                  scrollWithOffset(el);
                }}
                className={styles.list_items}
                id="Features"
                onClick={() => setIsOpen(false)}
              >
                Features
              </Link>
              <Link
                to="#contact"
                scroll={(el) => {
                  scrollWithOffset(el);
                }}
                className={styles.list_items}
                id="Contact"
                onClick={() => setIsOpen(false)}
              >
                Contact
              </Link>
              <Link to="https://www.transforma.in/" target="_">
                <img
                  alt="Transforma-logo"
                  className={styles.img_mobilelogo}
                  src={
                    process.env.PUBLIC_URL +
                    "/images/Redesignpics/TransformaLogo.png"
                  }
                />
              </Link>
              <Link
                to="https://www.linkedin.com/company/transforma-private-limited"
                target="_"
              >
                <Icon
                  as={AiFillLinkedin}
                  color="blue"
                  boxSize={8}
                  className={styles.img_mobilelogo}
                />
              </Link>
              <Link
                to="https://editlatex.transforma.in/editlatex_react/tryit"
                target="_"
                className={styles.trynow}
              >
                Try now !
              </Link>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
