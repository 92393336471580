import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  Stack,
} from '@chakra-ui/react'


function PeTools(props) {
  var selectedTools = []
  const allPeTools = [{ "name": "punc", "text": "Punctuation at end of captions.", "regex": [/\\caption\{(.*?)\}/g, /(.*)(?<!\.})$/g] },
  { "name": "zeros", "text": "Find leading zeros.", "regex": [/(^0+\d+| 0+\d+)/g] },
  { "name": "probability", "text": "Change probability casing.", "regex": [] },
  { "name": "number_to_words", "text": "Single digit number to words.", "regex": [/ (\d \w+|0\d \w+)/g] },
  { "name": "matching_quotes", "text": "Find Quotes matching.", "regex": [/'(.*)?'/g, /('{1})/g] },
  { "name": "en_dash", "text": "En dash in ranges.", "regex": [/(\d+ ?(-) ?\d+)/g] },
  { "name": "et_al", "text": "et al. in Italics.", "regex": [/et al\./g] }]

  const updateSelectedTools = function (checked, data) {
    console.log(data)
    if (checked) {
      selectedTools.push(data)
    } else {
      selectedTools = selectedTools.filter(function (el) { return el.name !== data.name; });
    }
  }

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pre-Editing Tools</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            {allPeTools.map((i, j) => <Checkbox onChange={(e) => updateSelectedTools(e.target.checked, i)} key={i.name}>{i.text}</Checkbox>)}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => { props.processTools(selectedTools); props.onClose(); }} mx={2} colorScheme='blue'>Submit</Button>
          <Button onClick={props.onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default PeTools;