import React from "react";
import styles from "./Advantagenew.module.css";

const Advantagenew = ({ isTablet }) => {
  let data = [
    {
      text: "Three window systems to view PDF and edit LaTeX parallelly.",
    },
    {
      text: "Just One click to view, edit and update math content.",
    },
    {
      text: "Regular text can also be edited as in any other text editing software with track changes and leaving comments.",
    },
    {
      text: "One click access to floating elements and references.",
    },
    {
      text: "Accessing and resolving queries upfront.",
    },
  ];
  return (
    <div className={styles.advantageouter}>
      <div className={styles.content}>
        {isTablet ? (
          <></>
        ) : (
          <img src={process.env.PUBLIC_URL + "/images/Redesignpics/1.svg"} />
        )}
        <div className={styles.maincontent}>
          <div className={styles.advantage_list}>
            <div className={styles.advpara}>
              <p>
                Major advantage of EditLaTeX is its handling of Math formulae.
                Math formulae are rendered with MathJaX when viewed, but it will
                be edited as LaTeX code. This speeds up the process when
                handling heavy math content.
              </p>
            </div>
            {isTablet ? (
              <div className={styles.advantageimg}>
                <img
                  alt="advantageimg"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/Redesignpics/feature4.webp"
                  }
                />
              </div>
            ) : (
              <></>
            )}
            <div className={styles.list}>
              <ul>
                {data.map((i, index) => {
                  return <li key={index}>{i.text}</li>;
                })}
              </ul>
            </div>
          </div>
          {!isTablet ? (
            <div className={styles.advantageimg}>
              <img
                alt="advantageimg"
                src={
                  process.env.PUBLIC_URL + "/images/Redesignpics/feature4.webp"
                }
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Advantagenew;
